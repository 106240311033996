@import "../../styles/Variables.css";

.modalBackground {
  width: 100vw;
  height: 100%;
  background-color: rgb(44, 44, 44, 0.6);
  position: fixed;
  top: 0;
  left: 0;
}

.modalContainer {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 50vw;
  min-height: 40vh;
  background-color: white;
  border-radius: 4px;
  z-index: 10;
  padding: 20px;
}

.modalContainer .modalBody {
  padding: 0 20px 20px 20px;
  min-height: fit-content;
}

.modalFooter {
  padding: 20px;
}

.modalCloseIcon {
  width: 100%;
  cursor: pointer;
}

.modalCloseIcon img {
  width: 15px;
  height: auto;
  float: right;
}

.modalTextArea {
  width: 100%;
  min-height: 150px;
  box-sizing: border-box;
  font-family: "MyWebFont";
  font-size: 16px;
  resize: none;
  border: 1px solid dimgray;
  border-radius: 2px;
}

.modalTextArea:focus {
  outline: none;
}
