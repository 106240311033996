@import "./styles/Styles.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginForm {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;

  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(-49%, -49%) rotate(0deg);
  }
  10% {
    transform: translate(-51%, -51%) rotate(-1deg);
  }
  20% {
    transform: translate(-53%, -50%) rotate(1deg);
  }
  30% {
    transform: translate(-47%, -48%) rotate(0deg);
  }
  40% {
    transform: translate(-49%, -51%) rotate(1deg);
  }
  50% {
    transform: translate(-51%, -48%) rotate(-1deg);
  }
  60% {
    transform: translate(-53%, -49%) rotate(0deg);
  }
  70% {
    transform: translate(-47%, -49%) rotate(-1deg);
  }
  80% {
    transform: translate(-51%, -51%) rotate(1deg);
  }
  90% {
    transform: translate(-49%, -48%) rotate(0deg);
  }
  100% {
    transform: translate(-49%, -52%) rotate(-1deg);
  }
}

.loginTitle {
  width: 100%;
  text-align: center;
}

.loginButtons {
  width: 100%;
  text-align: right;
}

.loginButton {
  border: 1px solid #ccc;
  margin-top: 5px;
  padding: 12px 20px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  background: white;
}

.loginButton:active {
  background: gray;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.24);
}

.loginForm > input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.footer {
  color: #b4b3b3;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

@font-face {
  font-family: "MyWebFont";
  src: url("fonts/Montserrat-Regular.ttf") format("truetype");
}

* {
  font-family: "MyWebFont";
}

body {
  background: url("images/minecraft_background.png");
}

.viewsContainer {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.viewsContainer > a {
  border: 1px solid black;
  flex-grow: 1;
  flex: 25%;
  height: 100px;
  text-align: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  margin: 10px;
  text-decoration: none;

  font-size: 20px;
  font-weight: bolder;
  color: dimgray;
}

.viewsContainer > a:hover {
  background: gray;
  color: white;
}

.viewsContainer > a > .viewFlex {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

.coversTable > table {
  width: 99%;
  margin: 10px auto;
  border: 1px solid;
  font-size: 20px;
  font-weight: bolder;
  color: dimgray;
  background: white;
  border-radius: 5px;
  border-spacing: 0;
}

.coversTable > table > thead > tr > td {
  font-size: 12px;
}

.coversTable > table > thead > tr > td:nth-child(1) {
  padding-left: 10px;
}

.coversTable > table > tbody > tr > td {
  vertical-align: top;
  height: 85px;
  padding: 10px 15px;
}

.coversTable > table > tbody > tr > td:nth-child(1) {
  text-align: center;
}

.coversTable > table > tbody > tr > td:nth-child(1) {
  padding-left: 10px;
}

.coversTable > table > tbody > tr:nth-child(even) {
  background-color: #f2f2f2;
}

.archivedRow {
  background-color: #d3dece;
}

.tableLink {
  color: dimgray;
}

.coversComment {
  font-size: 18px;
  font-weight: 200;
  margin: 15px 0;
}

.personalizationComment {
  font-size: 18px;
  font-weight: 900;
  margin: 15px 0;
}


.coversTableButtons,
.crystalBoardsTableButtons {
  display: flex;
}

.coversTableButtons > a,
.coversTableButtons > .printButton,
.editCoverContainerButtons > .opButton,
.numpadButton,
.personalizationTableButtons > .printButton,
.coversTableButtons > .correctButton,
.crystalBoardsTableButtons > .archiveButton {
  border: 1px solid black;
  width: 80px;
  height: 65px;
  text-align: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  margin: 0 5px 0 0;
  text-decoration: none;
  color: dimgray;
  font-size: 16px;
  font-weight: bolder;
}

.crystalBoardsTableButtons > .archiveButton {
  width: 150px;
}

.highlightedButton {
  background: #bee1ff;
}

.personalizationTableButtons > .printButton {
  width: 90px;
}

.personalizationTableButtons {
  display: flex;
}

.numpadButton {
  width: 100px;
  height: 100px;
}

.editCoverContainerButtons > .opButton {
  flex-grow: 1;
}

.coversTableButtons > a:hover,
.logoutPlate:hover,
.editCoverContainerButtons > .opButton:hover,
.numpadButton:hover,
.coversTableButtons > .correctButton:hover,
.crystalBoardsTableButtons > .archiveButton:hover {
  background: gray;
  color: white;
}

.personalizationTableButtons > .printButton:hover,
.coversTableButtons > .printButton:hover,
.crystalBoardsTableButtons > .archiveButton:hover {
  cursor: pointer;
}

.editButton,
.printButtonInner,
.logoutPlateLabel,
.opButtonInner,
.numpadButtonInner,
.correctButtonInner,
.archiveButtonInner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

.tablePageTopMenu,
.tablePageBottomMenu {
  display: flex;
  margin: 10px;
}

.tablePageTopMenu > input,
.tablePageTopMenu > button,
.tablePageTopMenu > select,
.tablePageBottomMenu > select {
  height: 50px;
  color: dimgray;
  font-size: 24px;
  font-weight: bolder;
  padding: 0 5px 0;
  text-transform: uppercase;
  font-family: "MyWebFont";
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.grow {
  flex-grow: 1;
}

.grow0 {
  flex-grow: 0;
}

.grow1 {
  flex-grow: 1;
}

.grow2 {
  flex-grow: 2;
}

.grow3 {
  flex-grow: 3;
}

.searchInput {
  width: 150px;
  text-align: center;
}

.limitInput {
  text-align: center;
}

.info {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 1px 1px gray;
  padding: 0 20px;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.editCoverContainerRow {
  display: flex;
}

.editCoverContainerColumn {
  flex: 49%;
}

.editCoverContainerColumnSeparator {
  flex: 1%;
}

.editCoverContainerColumn > input[type="text"],
.editCoverContainerColumn > select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background: white;
}

.editCoverContainerTitle {
  padding: 10px;
  width: 100%;
}

.editCoverContainerButtons {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.editCoverContainerButtons > button {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background: white;
}

.editCoverContainerColumn > .radioOptions {
  display: flex;
}

.editCoverContainerColumn > .radioOptions > div {
  flex-grow: 1;
}

.container,
.editCoverContainer,
.numpadContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 10px;
}

.editCoverContainer {
  width: 900px;
}

.numpadRow {
  display: flex;
}

.numpadButton {
  margin: 10px;
}

.numpadDark {
  background: #e5e5e5;
}

.inCorrection {
  background: yellow !important;
}

.correctionEndLabel {
  font-size: 12px;
}

.collapsableArea {
  width: 99%;
  margin: 10px auto;
  border: 1px solid;
  font-size: 20px;
  font-weight: bolder;
  color: dimgray;
  background: white;
  border-radius: 5px;
  border-spacing: 0;
  padding: 10px;
}

.collapsableElement {
}

.collapsableElementHeader {
  display: flex;
}

.personalizationProperties {
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  width: 810px;
  margin: 10px;
  display: none;
  font-size: 15px;
}

.stampingsPersonalizationProperties {
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  width: 250px;
  margin: 10px;
  display: none;
  font-size: 15px;
}

.show {
  display: block;
}

.top {
  vertical-align: top;
}

.personalizationProperty {
  display: flex;
}

.personalizationPropertyNotFound {
  color: #cacaca;
}

.frontPersonalization {
  display: flex;
}

.frontPersonalizationLeft {
  width: 350px;
}

.frontPersonalizationRight {
}

.coverModelWithWindowOrEmpty {
  color: red;
}

.redBox {
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.inlineBlock {
  display: inline-block
}

.ridgesTable > table {
  width: 60%;
  margin: 10px auto;
  border: 1px solid;
  font-size: 20px;
  font-weight: bolder;
  color: dimgray;
  background: white;
  border-radius: 5px;
  border-spacing: 0;
}

.ridgesTable > table > thead > tr > td {
  font-size: 12px;
}

.ridgesTable > table > thead > tr > td:nth-child(1) {
  padding-left: 10px;
}

.ridgesTable > table > tbody > tr > td {
  vertical-align: middle;
  height: 32px;
}

.ridgesTable > table > tbody > tr > td:nth-child(1) {
  text-align: center;
}

.ridgesTable > table > tbody > tr > td:nth-child(1) {
  padding-left: 10px;
}

.ridgesTable > table > tbody > tr:nth-child(even) {
  background-color: #f2f2f2;
}

.orderId {
  font-size: 15px;
}

.editIcon {
  width: 20px;
  height: 20px;
  opacity: 0.2;
}

.editIcon:hover {
  opacity: 1;
}

.comment {
  font-size: 16px;
  font-weight: normal;
}

.mediasList {
  margin-left: 40px;
}