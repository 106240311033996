.btnLink {
  font-size: 16px;
  font-weight: 200;
  text-decoration: underline;
  width: fit-content;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: dimgray;
  text-align: left;
}

.btn {
  width: fit-content;
  height: 40px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
  border-color: transparent;
  cursor: pointer;
}

.btnPrimary {
  background-color: dimgray;
  color: white;
}

.btnSecondary {
  background-color: white;
  color: dimgray;
  border: 1px solid dimgray;
}

.btnGroup {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
